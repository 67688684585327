import React, { useEffect, useRef, FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import islBlue from "./img/isl_blue.png";
import islMainImage1 from "./img/commit-flow-shortened-100.gif";
import branchFlow from "./img/branch-flow-zoom-compress.gif";
import interactiveRebase from "./img/interactive-rebase.gif";
import staging from "./img/unstaged-changes-zoom-compress.gif";
import rebasing from "./img/rebase-flow.gif";
import { isMobile } from "react-device-detect";

import "./App.css";

interface AppProps {}

const App: FunctionComponent<AppProps> = () => {
  const sliderRef = useRef<Slider | null>(null);

  useEffect(() => {
    window.gtag_report_conversion = function (url?: string) {
      var callback = function () {
        if (typeof url !== "undefined") {
          window.location.href = url;
        }
      };
      window.gtag("event", "conversion", {
        send_to: "AW-16583298873/tPkJCOnLpLQZELmexOM9",
        event_callback: callback,
      });
      return false;
    };
  }, []);

  const gtagReportConversion = (url?: string) => {
    window.gtag("event", "conversion", {
      event_category: "engagement",
      event_label: url,
    });
  };

  const handleInstallClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isMobile) {
      return;
    }
    gtagReportConversion();
    window.gtag_report_conversion(
      "vscode:extension/interactive-smartlog.interactive-smartlog"
    );
  };

  return (
    <div className="App">
      <Helmet>
        <title>Interactive Git Log (IGL)</title>
        <meta
          name="description"
          content="Interactive Git Log (IGL) is the most powerful Git GUI for VSCode, streamlining and enhancing your interaction with Git repositories."
        />
        <meta
          name="keywords"
          content="Git, VSCode, Git Log, IGL, Interactive Smart Log"
        />
        <link rel="canonical" href="https://yourdomain.com" />
      </Helmet>
      <header className="App-header">
        <div className="logoContainer">
          <img className="logo" src={islBlue} alt="ISL Logo" />
          <span className="iglLogoText">Interactive Git Log</span>
        </div>
      </header>
      <div className="top-section">
        <div className="app-header-contents">
          <h1 className="headerText">Revolutionize your Git Workflow</h1>
          <h2 className="subHeader">
            Interactive Git Log (IGL) is the most powerful Git GUI for VSCode,
            streamlining and enhancing your interaction with Git repositories.
          </h2>
          <div className="installContainer">
            <a
              className="installButton"
              onClick={handleInstallClick}
              href={
                isMobile
                  ? "https://marketplace.visualstudio.com/items?itemName=interactive-smartlog.interactive-smartlog"
                  : "vscode:extension/interactive-smartlog.interactive-smartlog"
              }
              style={{
                cursor: "pointer",
              }}
            >
              Install
            </a>
          </div>
        </div>
      </div>
      <div className="body">
        <div className="overlay"></div>
        <div className="imgContainer">
          <Slider
            lazyLoad="progressive"
            ref={sliderRef}
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            beforeChange={(_current: any, _next: any) => {
              const gifs =
                document.querySelectorAll<HTMLImageElement>(".normalImg");
              gifs.forEach((gif) => {
                gif.src = gif.src;
              });
            }}
            nextArrow={
              <Arrow
                onClick={() => sliderRef?.current?.slickNext()}
                prev={false}
              />
            }
            prevArrow={
              <Arrow
                onClick={() => sliderRef?.current?.slickPrev()}
                prev={true}
              />
            }
          >
            <div>
              <img
                src={islMainImage1}
                alt="Commit Flow Visual"
                className="normalImg"
              />
              <div className="imageDescriptionContainer">
                <h2 className="imgDescription">✔️ Making Commits</h2>
                <p>
                  Underneath your uncommitted changes, there's a <b>Commit</b>{" "}
                  button and an <b>Amend</b> button. Clicking these buttons
                  opens up the commit form sidebar on the right side, where you
                  can write a detailed commit message.
                  <br />
                  <br />
                  When you're satisfied with your message, the <i>
                    Commit
                  </i> and <i>Amend</i> buttons at the bottom right will let you
                  create or amend your commit using your message.
                  <br />
                  <br />
                  <b>
                    While a command is running, you will see progress
                    information at the bottom of the screen.
                  </b>{" "}
                  This is also where you can see error messages if something
                  goes wrong when running a command. IGL shows the arguments
                  used to run commands, so you could replicate the behavior on
                  the CLI if you want to.
                </p>
              </div>
            </div>
            <div>
              <img
                src={branchFlow}
                alt="Another Visual"
                className="normalImg"
              />
              <div className="imageDescriptionContainer">
                <h2 className="imgDescription">🌳 Branches</h2>
                <p>
                  <b>
                    IGL displays a tree view of your commits and branches,
                    starting from where they diverge from the primary remote
                    branch
                  </b>{" "}
                  (usually <code>origin/main</code> or{" "}
                  <code>origin/master)</code>.
                  <br />
                  <br />
                  Rather than typing branch names, you can directly click on
                  branch tags to go to them. You can also add, remove, and fetch
                  branches directly from IGL.
                </p>
              </div>
            </div>
            <div>
              <img src={staging} alt="Another Visual" className="normalImg" />
              <div className="imageDescriptionContainer">
                <h2 className="imgDescription">✨ Uncommitted Changes</h2>
                <p className="imgSubdescription">
                  <b>
                    Changes to files in your working copy appear automatically
                    in IGL, just like if you had run <code>git status</code>
                  </b>
                  . The color and icon next to files shows you if a file was
                  modified, added, or removed. The opacity of the filename
                  indicates whether or not the file has been staged (via{" "}
                  <code>git add</code>) for commit. You can click on files to
                  open them.
                  <br />
                  <br />
                  When you hover over files listed under uncommitted changes,
                  IGL dynamically presents you with a set of actionable buttons.
                  These <b>buttons run Git commands</b>, making it effortless to
                  stage or unstage files for commit, revert modifications, or
                  even remove newly added files from the file system without
                  resorting to the command line.
                </p>
              </div>
            </div>
            <div>
              <img src={rebasing} alt="Another Visual" className="normalImg" />
              <div className="imageDescriptionContainer">
                <h2 className="imgDescription">
                  ⚔️ Rebasing and resolving conflicts
                </h2>
                <p>
                  You can hover over a branch to reveal a rebase button.
                  Pressing the button rebases your current branch onto the
                  target branch. When merge conflicts are detected, IGL will add
                  to the list of uncommitted changes a list of unresolved
                  conflicts.
                  <br />
                  <br />
                  <b>
                    After opening each file and resolving the conflict markers,
                    you can click the plus button next to each file in IGL to
                    mark it as resolved.
                  </b>
                  When all files have been resolved, you are free to continue
                  the command that led to conflicts.
                  <br />
                  <br />
                  It is possible to hit merge conflicts multiple times, for
                  example, when rebasing an entire stack of commits, as each
                  commit is checked for conflicts one-by-one.
                </p>
              </div>
            </div>
            <div>
              <img
                src={interactiveRebase}
                alt="Another Visual"
                className="normalImg"
              />
              <div className="imageDescriptionContainer">
                <h2 className="imgDescription">
                  🔧 Reorder, squash, or drop commits
                </h2>
                <p>
                  An <b>Edit stack</b> button will appear when you are on a
                  branch with multiple commits. Upon clicking <i>Edit stack</i>,
                  a dialog will open in which you can reorder, squash, and drop
                  commits. Press <i>Save changes</i> triggers IGL runs a git
                  interactive rebase to edit the stack.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

interface ArrowProps {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  prev: boolean;
}

const Arrow: React.FC<ArrowProps> = ({ onClick, prev }) => {
  return (
    <div
      style={{ display: "block" }}
      onClick={onClick}
      className={"arrow" + (prev ? " prevArrow" : " nextArrow")}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.5rem"
        viewBox="0 0 14 8"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.292892 0.292894C0.683418 -0.0976306 1.31658 -0.0976306 1.70711 0.292894L7 5.58579L12.2929 0.292894C12.6834 -0.0976306 13.3166 -0.0976306 13.7071 0.292894C14.0976 0.683418 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292892 1.70711C-0.0976306 1.31658 -0.0976306 0.683418 0.292892 0.292894Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default App;
